exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cloud-variable-badge_badge_1n_s5 {\n    padding: 0.5rem;\n    margin: 5px 0 8px 0;\n    border: 1px solid #b9d6ff;\n    background-color: #dbebff;\n    display: flex;\n    align-items: center;\n    border-radius: 0.5rem;\n}\n[theme=\"dark\"] .cloud-variable-badge_badge_1n_s5 {\n    border-color: #203652;\n    background-color: #16202c;\n}\n.cloud-variable-badge_icon_3ph5l {\n    margin-right: 0.25rem;\n}\n[theme=\"dark\"] .cloud-variable-badge_icon_3ph5l {\n    filter: invert(100%);\n}\n.cloud-variable-badge_text_2zFOB {\n\n}\n", ""]);

// exports
exports.locals = {
	"badge": "cloud-variable-badge_badge_1n_s5",
	"icon": "cloud-variable-badge_icon_3ph5l",
	"text": "cloud-variable-badge_text_2zFOB"
};